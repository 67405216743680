import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LatestDownloadBar from "~/components/LatestDownloadBar/LatestDownloadBar";
import WhatsNew from "~/components/WhatsNew/WhatsNew";
export const _frontmatter = {
  "menuLabel": "What's New",
  "sortOrder": 2
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`What's New`}</h1>
    <h2>{`Component Pages Published`}</h2>
    <h5>{`Jan 11th, 2023`}</h5>
    <p>{`Component pages have been developed and published!  All of our components in Caesars UI will have documentation pages where you can get all the information you need to use your component.  This applies to both web and iOS Native components.  `}</p>
    <p>{`Check out the first of them, for `}<a parentName="p" {...{
        "href": "/components/market-button"
      }}>{`Market Button`}</a></p>
    <hr></hr>
    <h2>{`Update 0.0.12 - Component Releases`}</h2>
    <h5>{`Dec 6th, 2022`}</h5>
    <ul>
      <li parentName="ul">{`Token fixes`}</li>
      <li parentName="ul">{`Canned Parlay / Carousel Components via Matt Greenberg (`}<a parentName="li" {...{
          "href": "mailto:mgreenberg@caesars.com"
        }}>{`mgreenberg@caesars.com`}</a>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/market-button"
        }}>{`Market Button`}</a></li>
    </ul>
    <hr></hr>
    <h2>{`Minor update 0.0.8 beta`}</h2>
    <h5>{`Oct 28th, 2022`}</h5>
    <ul>
      <li parentName="ul">{`More minor token adjustments`}</li>
    </ul>
    <hr></hr>
    <h2>{`Announcements and Updates`}</h2>
    <h5>{`Oct 12th, 2022`}</h5>
    <p>{`Caesars UI tokens have been fully integrated into the existing Cordova `}<inlineCode parentName="p">{`sportsbook-web-nj`}</inlineCode>{` project codebase.   What does this mean for you? `}</p>
    <ul>
      <li parentName="ul">{`If you merge master into your current feature branch, you will notice a bunch of CSS variable changes.  These should all be accepted if any merge conflicts are occuring.`}</li>
      <li parentName="ul">{`If you've been working on a feature and have branched off master prior to 10/14/2022, you should be prepared to replace any old token values with our new Caesars UI tokens.   A full mapping document can be found here: `}<a parentName="li" {...{
          "href": "https://caesars365-my.sharepoint.com/:x:/g/personal/mmessina_williamhill_us/EU8NIoHHSHFOuy9NKKNIYawBBY274llafL6lUk1ZC2WxUw?e=mWealc"
        }}>{`Cordova to Caesars UI Token Mapping`}</a>{`.  If you have any questions at all please reach out to the design system team via our slack channel #caesars-ui and we'll be more than happy to provide direction.`}</li>
      <li parentName="ul">{`You will begin seeing new design specs utilizing these token names, as of 10/14/2022, old tokens will be considered deprecated and not to be used under any circumstances.  `}</li>
    </ul>
    <p>{`A multi step phase out of the UI Toolkit themes will take place, merging any existing components into the Caesars UI component library as legacy components and eventually sunsetting the UI Toolkit project.  We will make announcements every step of the way.  This should be low diruption, mostly invisible to your workflow, other than being aware of these incoming changes and the use of the new tokens.`}</p>
    <hr></hr>
    <h2>{`Minor update 0.0.7 beta`}</h2>
    <h5>{`Oct 12th, 2022`}</h5>
    <ul>
      <li parentName="ul">{`More minor token adjustments.  status-negative gets new color value.`}</li>
    </ul>
    <hr></hr>
    <h2>{`Minor update 0.0.6 beta`}</h2>
    <h5>{`Oct 10th, 2022`}</h5>
    <ul>
      <li parentName="ul">{`Changed token names/values based on designer feedback`}</li>
    </ul>
    <hr></hr>
    <h2>{`Bugfix 0.0.5 beta`}</h2>
    <h5>{`Sept 9th, 2022`}</h5>
    <ul>
      <li parentName="ul">{`Removed CSS Reset from Tailwind in the Component Library`}</li>
      <li parentName="ul">{`Updated Android export for names, and fixed font weights.`}</li>
    </ul>
    <hr></hr>
    <h2>{`Release 0.0.4 beta`}</h2>
    <h5>{`August 28th, 2022`}</h5>
    <p>{`Our next set of tokens have arrived. This release has:`}</p>
    <ul>
      <li parentName="ul">{`Foundations and core tokens consisting of `}<a parentName="li" {...{
          "href": "/foundations/depth"
        }}>{`depth`}</a>{`, `}<a parentName="li" {...{
          "href": "/foundations/shape"
        }}>{`shape`}</a>{`, `}<a parentName="li" {...{
          "href": "/foundations/spacing"
        }}>{`spacing`}</a>{` and `}<a parentName="li" {...{
          "href": "/foundations/typography"
        }}>{`typography`}</a>{`.`}</li>
      <li parentName="ul">{`Because those core tokens can be overwhelming, we've created semantic tokens for each of those sets: `}<a parentName="li" {...{
          "href": "/tokens/border-radius"
        }}>{`border radius`}</a>{`, `}<a parentName="li" {...{
          "href": "/tokens/border-width"
        }}>{`border-width`}</a>{`, `}<a parentName="li" {...{
          "href": "/foundations/box-shadow"
        }}>{`box-shadow`}</a>{`, and `}<a parentName="li" {...{
          "href": "/tokens/spacing"
        }}>{`spacing`}</a>{`.`}</li>
      <li parentName="ul">{`Created Figma box shadows styles, spacing and notation components that designers could use to redline their frames.`}</li>
    </ul>
    <hr></hr>
    <h2>{`Release 0.0.3 beta`}</h2>
    <h5>{`July 28th, 2022`}</h5>
    <p>{`Caesars UI's first beta release! Here's what's in this initial release:`}</p>
    <ul>
      <li parentName="ul">{`Created our `}<a parentName="li" {...{
          "href": "/foundations/color-system"
        }}>{`core color system`}</a>{` that will be used for all of Caesars Digital's brands. This also includes colors for dark themes.`}</li>
      <li parentName="ul">{`Established the first set of `}<a parentName="li" {...{
          "href": "/tokens/colors"
        }}>{`semantic color tokens`}</a>{`. These tokens will align the visual consistency all of Caesars Digital's products. It will also allow us to quickly change any design decisions that happen in the future. `}</li>
      <li parentName="ul">{`Provided instructions with the launch of the design system, making it easy for `}<a parentName="li" {...{
          "href": "/getting-started/getting-started-for-designers"
        }}>{`designers`}</a>{` and `}<a parentName="li" {...{
          "href": "/getting-started/getting-started-for-developers"
        }}>{`developers`}</a>{` to get started.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      